@charset "UTF-8";


/*=======================================
        Imports
=======================================*/

// Normalize 3.0.3
@import "vendor/normalize/normalize";

/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

// Dependencies for foundation
@import 'vendor/sassy-lists/helpers/missing-dependencies';
@import 'vendor/sassy-lists/helpers/true';
@import 'vendor/sassy-lists/functions/contain';
@import 'vendor/sassy-lists/functions/purge';
@import 'vendor/sassy-lists/functions/remove';
@import 'vendor/sassy-lists/functions/replace';
@import 'vendor/sassy-lists/functions/to-list';

// Settings
// import your own `settings` here or
// import and modify the default settings through
// @import 'settings/settings';
@import "partials/foundation-settings";

// Sass utilities
@import 'vendor/foundation/util/util';

// Global variables and styles
@import 'vendor/foundation/global';

// Components
@import 'vendor/foundation/grid/grid';
// @import 'vendor/foundation/xy-grid/xy-grid';
@import 'vendor/foundation/typography/typography';
@import 'vendor/foundation/forms/forms';
@import 'vendor/foundation/components/visibility';
@import 'vendor/foundation/components/float';
@import 'vendor/foundation/components/button';
@import 'vendor/foundation/components/button-group';
@import 'vendor/foundation/components/accordion-menu';
@import 'vendor/foundation/components/accordion';
// @import 'vendor/foundation/components/badge';
// @import 'vendor/foundation/components/breadcrumbs';
@import 'vendor/foundation/components/callout';
@import 'vendor/foundation/components/card';
@import 'vendor/foundation/components/close-button';
@import 'vendor/foundation/components/drilldown';
@import 'vendor/foundation/components/dropdown-menu';
@import 'vendor/foundation/components/dropdown';
@import 'vendor/foundation/components/flex';
@import 'vendor/foundation/components/responsive-embed';
@import 'vendor/foundation/components/label';
@import 'vendor/foundation/components/media-object';
@import 'vendor/foundation/components/menu';
@import 'vendor/foundation/components/menu-icon';
// @import 'vendor/foundation/components/off-canvas';
// @import 'vendor/foundation/components/orbit';
@import 'vendor/foundation/components/pagination';
// @import 'vendor/foundation/components/progress-bar';
// @import 'vendor/foundation/components/reveal';
// @import 'vendor/foundation/components/slider';
// @import 'vendor/foundation/components/sticky';
// @import 'vendor/foundation/components/switch';
@import 'vendor/foundation/components/table';
@import 'vendor/foundation/components/tabs';
@import 'vendor/foundation/components/title-bar';
@import 'vendor/foundation/components/top-bar';
@import 'vendor/foundation/components/thumbnail';
// @import 'vendor/foundation/components/tooltip';
// @import 'vendor/foundation/prototype/prototype';

@mixin foundation-everything(
  $flex: true,
  $prototype: false
) {
  @if $flex {
    $global-flexbox: true !global;
  }

  @include foundation-global-styles;
  @if not $flex {
    @include foundation-grid;
  }
  @else {
    @if $xy-grid {
      @include foundation-xy-grid-classes;
    }
    @else {
      @include foundation-flex-grid;
    }
  }
  @include foundation-typography;
  @include foundation-forms;
  @include foundation-button;
  @include foundation-accordion;
  @include foundation-accordion-menu;
  // @include foundation-badge;
  // @include foundation-breadcrumbs;
  @include foundation-button-group;
  @include foundation-callout;
  @include foundation-card;
  @include foundation-close-button;
  @include foundation-menu;
  @include foundation-menu-icon;
  @include foundation-drilldown-menu;
  @include foundation-dropdown;
  @include foundation-dropdown-menu;
  @include foundation-responsive-embed;
  @include foundation-label;
  @include foundation-media-object;
  // @include foundation-off-canvas;
  // @include foundation-orbit;
  @include foundation-pagination;
  // @include foundation-progress-bar;
  // @include foundation-slider;
  // @include foundation-sticky;
  // @include foundation-reveal;
  // @include foundation-switch;
  @include foundation-table;
  @include foundation-tabs;
  @include foundation-thumbnail;
  @include foundation-title-bar;
  // @include foundation-tooltip;
  @include foundation-top-bar;
  @include foundation-visibility-classes;
  @include foundation-float-classes;

  @if $flex {
    @include foundation-flex-classes;
  }

  @if $prototype {
    @include foundation-prototype-classes;
  }
}

@include foundation-everything();

@import "partials/fonts";
@import "partials/typography";

// Flexslider 2.4.0
@import "vendor/flexslider/flexslider";

// Fancybox 2.1.5
@import "vendor/fancybox/jquery.fancybox";

// ImageLightBox by http://osvaldas.info/image-lightbox-responsive-touch-friendly
@import "vendor/imagelightbox/imagelightbox";

// Architecture Settings
@import "partials/hlm-mixins";
@import "partials/hlm-functions";

// HLM Base Styles
@import "partials/base";


/*=======================================
          HLM Modules
=======================================*/
@import "partials/module-includes";

// Gallery Options
@import "modules/gallery/ThumbnailLayout";
@import "modules/gallery/ThumbnailSlider";

// HLM Sections
@import "sections/header";
@import "sections/layouts";
@import "sections/footer";
@import "sections/page-home";
@import "sections/sub-banner";
@import "sections/auction-page";

// HLM Other
@import "partials/accordions";
@import "partials/account";
@import "partials/coupons";
@import "partials/blog-grid";
// @import "partials/events";
@import "partials/top-bar";
@import "partials/form-controls";
@import "partials/myce-callouts";
@import "partials/subnav";
@import "partials/products";
// @import "partials/food-menus";
@import "partials/sml";
@import "partials/print-machine";
@import "partials/buy-page";
@import "partials/articles";

// HLM PPC
// @import "ppc/main-ppc";

/*=======================================
      Custom Site Styles
=======================================*/
.featured-machines{
  @include clearfix;
  padding: rem-calc(30 0);
	background: #fff;
	.featured-machines-heading{
		margin: rem-calc(0 0 30);
		letter-spacing: rem-calc(-3);
		text-align: center;
    text-transform: uppercase;
    line-height: 1.1;
		color: #3d3d3d;
		font-size: rem-calc(60);
    font-weight: 300;
    font-family: $header-font-family;
		strong{
			color: $primary-color;
			font-weight: 900;
		}
	}

	@include breakpoint(large){
		padding: rem-calc(60);
	}
}

.popular-searches{
  @include clearfix;
  padding-bottom: rem-calc(30);
  background: #fff;

  .heading{
		margin: rem-calc(0 0 30);
		letter-spacing: rem-calc(-3);
		text-align: center;
    text-transform: uppercase;
    line-height: 1.1;
		color: #3d3d3d;
		font-size: rem-calc(60);
		font-weight: 300;
		strong{
			color: $primary-color;
			font-weight: 900;
		}
  }

  ul{
    margin: rem-calc(0);
    list-style: none;
    text-align: center;
    a{
      display: inline-block;
      margin-bottom: rem-calc(4);
      font-weight: 700;
    }
  }

  @include breakpoint(large){
    padding-bottom: rem-calc(60);
    ul{
      margin: rem-calc(0 0 30);
    }

    .columns:nth-child(2){
      position: relative;
      &::before, &::after{
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: rem-calc(1);
        border-left: 1px solid #dbdbdb;
        left: 0;
      }
      &::after{
        top: 0;
        right: 0;
        left: auto;
      }
    }
  }
}

//////////////////
// Contact Page //
//////////////////
#page-id-4 .content{

  .contact-box{
    border: 1px solid #aaa;
    text-align: center;
    height: calc(100% - 30px);
    padding: rem-calc(30);
    margin-bottom: rem-calc(30);

    .noframe{
      margin-bottom: rem-calc(20);
    }
  }

  p{
    font-family: $special-font-family;
    margin-bottom: rem-calc(10);
    line-height: 1.3;
  }

  a{
    &[href*="tel:"]{
      color: #BD3A2A;
      font-weight: 700;
      font-size: rem-calc(24);
    }
  }
}

.production-lines{
  background: url('/images/production-lines-bg.jpg') center no-repeat;
  background-size: cover;
  text-align: center;

  img{
    display: block;
    margin: 0 auto rem-calc(20);
  }

  h3{
    font-family: $header-font-family;
    line-height: 1.2;
    font-weight: 900;
    font-size: rem-calc(32);
    color: #4E4E4E;
    text-transform: uppercase;
  }

  p{
    font-size: rem-calc(22);
  }
}

.lines-for-sale{
  h2{
		margin: rem-calc(0 0 30);
		font-size: rem-calc(30);
		letter-spacing: rem-calc(-3);
		text-align: center;
    text-transform: uppercase;
    line-height: 1.1;
		color: #3d3d3d;
		font-weight: 300;
		strong{
			color: $primary-color;
			font-weight: 900;
		}
  }
  @include breakpoint(large){
    h2{
		  font-size: rem-calc(60);
    }
  }
}

#page-id-143{
  form{
    max-width: rem-calc(600);
    margin: 0 auto rem-calc(30);
    background: $primary-color;
    padding: rem-calc(20 30);

    .form-instructions{
      color: #fff;
      margin-bottom: rem-calc(20);
    }
  }
}