/*=======================================
				Banner
=======================================*/

$sub-banner-bgcolor: darken($primary-color, 20%);
$sub-banner-background-small: url("/images/sub-banner.jpg");
$sub-banner-background: $sub-banner-background-small;

// Banner Heading
$sub-banner-h1-display: block;
$sub-banner-large-height: rem-calc(270);
$sub-banner-h1-padding-small: rem-calc(380 15 20 15);
$sub-banner-h1-padding-medium: rem-calc(390 15 20 15);
$sub-banner-h1-padding-large: rem-calc(380 15 30 15);
$sub-banner-h1-text-color: dynamic-text-color($sub-banner-bgcolor);
$sub-banner-h1-font-size-small: rem-calc(32);
$sub-banner-h1-font-size: rem-calc(44);
$sub-banner-h1-font-weight: 400;
$sub-banner-h1-border-bottom: none;
$sub-banner-h1-border-spacing: rem-calc(0);

.sub-banner {
  position: relative;
  background-color: $sub-banner-bgcolor;
  background-image: $sub-banner-background-small;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  text-align: center;
  min-height: rem-calc(360);

  #sub-banner-container > *,
  #sub-banner-container div > * {
    @extend h1;
    line-height: 1.2;
    display: $sub-banner-h1-display;
    margin: $sub-banner-h1-padding-small;
    padding-bottom: $sub-banner-h1-border-spacing;
    border-bottom: $sub-banner-h1-border-bottom;
    color: $sub-banner-h1-text-color;
    font-size: $sub-banner-h1-font-size-small;
    font-weight: $sub-banner-h1-font-weight;
  }

  .sub-banner-message {
    max-width: rem-calc(830);
    margin: 0 auto rem-calc(30);
    padding: rem-calc(0 15);
    color: #fff;
    font-size: rem-calc(18);
    font-weight: 700;
    strong {
      font-weight: 900;
    }
  }

  .button {
    background: #bd3a2a;
    &:hover {
      background: darken(#bd3a2a, 10%);
    }
  }

  @include breakpoint(medium) {
    background-image: $sub-banner-background;

    #sub-banner-container > *,
    #sub-banner-container div > * {
      margin: $sub-banner-h1-padding-medium;
      color: $sub-banner-h1-text-color;
      font-size: $sub-banner-h1-font-size;
    }
  }

  @include breakpoint(large) {
    min-height: $sub-banner-large-height;
    padding-bottom: rem-calc(90);

    #sub-banner-container > *,
    #sub-banner-container div > * {
      margin: $sub-banner-h1-padding-large;
      .sub-banner-message {
        margin: 0 auto rem-calc(80);
      }
    }
  }

  &.machines-sub-banner {
    padding: rem-calc(30 0);
    color: #fefefe;
    background: #860000;
    p {
      max-width: rem-calc(690);
      margin: 0 auto rem-calc(30);
      color: #8cb5d6;
      strong {
        font-weight: 900;
      }
    }
    label {
      text-align: left;
      color: #fefefe;
      font-family: $header-font-family;
      text-transform: uppercase;
      font-weight: 800;
      font-size: rem-calc(18);
    }
    select {
      line-height: 1;
      border: 0;
      border: 1px solid #343434;
      background: url("/images/select-arrow.jpg") center right no-repeat, #fff;
    }

    h1 {
      margin-top: rem-calc(310);

      @include breakpoint(medium) {
        margin-top: rem-calc(340);
      }
      @include breakpoint(large) {
        margin-top: rem-calc(250);

        &:only-child {
          margin-top: rem-calc(280);
        }
      }
    }
  }

  &.buy-used-banner {
    background-image: url("/images/buy-used-banner.jpg");

    .button {
      background: rgba(#000, 0.27);
      border: 1px solid rgba(#fff, 0.38);
      font-size: rem-calc(18);

      &:hover,
      &:focus {
        background: rgba(#000, 0.5);
      }
    }

    @include breakpoint(large) {
      padding: rem-calc(340 0 50);
      h1 {
        font-size: rem-calc(52);
        margin: rem-calc(0 0 20);
      }
    }
  }
}
